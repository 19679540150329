import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "assets/scss/style.scss";
import Button from "components/Button";

import ReactGA from 'react-ga';

const LandingPage = lazy(() => import("pages/LandingPage"));
const BlogPage = lazy(() => import("pages/BlogPage"));
const ProjectPage = lazy(() => import("pages/ProjectPage"));
const ProjectDetailPage = lazy(() => import("pages/ProjectDetailPage"));
const Certifications = lazy(() => import("pages/Certifications"));
const ContactPage = lazy(() => import("pages/ContactPage"));
const LinksPage = lazy(() => import("pages/LinksPage"));
const Error404Page = lazy(() => import("pages/Error404Page"));

function App() {
	
  useEffect(() => {
	ReactGA.initialize('G-61Q4H7LF20');
	ReactGA.set({ page: window.location.pathname }); // Update the user's current page
	ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
  }, []);

  return (
    <div className="App">
      <Router>
        <Suspense fallback={<Button isLoading loadCenter />}>
          <Switch>
            <Route exact path="/" component={LandingPage}></Route>
            <Route path="/blogs/" component={BlogPage}></Route>
            <Route path="/projects/" component={ProjectPage}></Route>
            <Route path="/project/:id/:nameapplication/" component={ProjectDetailPage}></Route>
            <Route path="/certifications/" component={Certifications}></Route>
            <Route path="/contact/" component={ContactPage}></Route>
            <Route path="/links/" component={LinksPage}></Route>
            <Route component={Error404Page}></Route>
          </Switch>
        </Suspense>
      </Router>
    </div>
  )
}

export default App;
